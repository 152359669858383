import React, { useMemo } from 'react';
import { ILoginProps } from '../types';
import { ELoginMode } from '../../../constants/enums';
import { StyledLoginContainer } from '../style';
import { UsernamePasswordMode } from '../LoginMode/username';
import { LoginIdMode } from '../LoginMode/loginId';
import { OtpMode } from '../LoginMode/OTP';
import { ProvidersMode } from '../LoginMode/providers';
import { useLoginPage } from '../useLoginPage.hook';
import { useTranslation } from 'react-i18next';

export const LoginButtonBlocks: React.FC<ILoginProps & { maxWidth?: string }> = ({
  mode,
  openInfoModal,
  loginResponse,
  stopLoader,
  startLoader,
  loginButtonClickWrapper,
  setMode,
  otpProofKey,
  otpTokenKey,
  maxWidth = '400px',
}) => {
  const { publisherMetaData, isWebview } = useLoginPage();
  const { t } = useTranslation();

  const openHowToFindId = () => {
    openInfoModal(publisherMetaData.integration.playersAuthentication.userTokenUrl);
  };

  const showUserTokenLink = useMemo(
    () =>
      (mode === ELoginMode.PROVIDERS || mode === ELoginMode.TOKEN_ID) &&
      publisherMetaData.integration.playersAuthentication.userTokenUrl &&
      publisherMetaData.integration.playersAuthentication.userTokenText &&
      publisherMetaData.integration.playersAuthentication.userTokenOn &&
      !isWebview,
    [publisherMetaData.integration.playersAuthentication, isWebview]
  );

  return (
    <StyledLoginContainer maxWidth={maxWidth}>
      {mode === ELoginMode.PROVIDERS && (
        <ProvidersMode
          loginResponse={loginResponse}
          stopLoader={stopLoader}
          startLoader={startLoader}
          loginButtonClickWrapper={loginButtonClickWrapper}
          setMode={setMode}
        />
      )}
      {mode === ELoginMode.USERNAME && <UsernamePasswordMode submit={loginResponse} />}
      {mode === ELoginMode.TOKEN_ID && <LoginIdMode submit={loginResponse} />}
      {(otpProofKey || mode === ELoginMode.OTP) && (
        <OtpMode submit={loginResponse} otpProofKey={otpProofKey} otpTokenKey={otpTokenKey} />
      )}
      {showUserTokenLink && (
        <p className="userTokenLink" onClick={openHowToFindId}>
          {t('login.canNotFindPlayerId')}
        </p>
      )}
    </StyledLoginContainer>
  );
};
